import React from "react";

import portadacirma01 from "../images/portadas/portada_cirma_01.jpg";
import portadacirma02 from "../images/portadas/portada_cirma_02.jpg";
import portadacirma03 from "../images/portadas/portada_cirma_03.jpg";
import portadacirma04 from "../images/portadas/portada_cirma_04.jpg";
import portadacirma05 from "../images/portadas/portada_cirma_05.jpg";
import portadacirma06 from "../images/portadas/portada_cirma_06.jpg";

export default function TopSection(props){
    const {children} = props;
    const images = [
        portadacirma01, // Enero - Febrero
        portadacirma01, // Marzo - Abril
        portadacirma03, // Mayo - Junio
        portadacirma04, // Julio - Agosto
        portadacirma05, // Septiembre - Octubre
        portadacirma06  // Noviembre - Diciembre
    ];

    // Obtener el mes actual (1-12)
    const month = new Date().getMonth() + 1; 

    // Calcular el bimestre (0-5)
    const bimestreIndex = Math.floor((month - 1) / 2);

    // Seleccionar la imagen según el bimestre
    const backgroundImage = images[bimestreIndex];

    return <section className="c-top-section" style={{backgroundImage: `url(${backgroundImage})`}}>
        <div className="c-top-section-overlay"> 
        </div>
        {children}
    </section>;
}